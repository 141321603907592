import { TableManger, COLUMN_CODE, TABLES } from "../managers/TableManger";
import AppManager from "../managers/AppManager";
import ActivityManager from "../managers/ActivityManager";
import { INPUT_TYPE } from "deskera-ui-library";
import Utility, {
  getRandomAlphaNumericString,
  sanitizeJSON,
  toCurrencyFormat
} from "../utility/Utility";
import { getFullName } from "../model/User";
import { INVITE_USER_STATUS, USER_ACTION_TYPES } from "../constants/Permission";
import { DealManager } from "../managers/DealManager";
import ApiConstants from "../constants/ApiConstants";

import { DKLabel } from "deskera-ui-library";
import {
  CAMPAIGN_TYPE,
  FILTER_LOGICAL_OPERATORS,
  FILTER_OPERATORS,
  PRICE_BOOK_PERMISSION_ERROR_CODE,
  TICKET_PRIORITIES,
  TICKET_STATUS,
  TICKET_TYPES
} from "../constants/Enum";
import { AccountService } from "../services/accounts";
import { showAddAccountPopup } from "../components/common/accounts/AddAccounts";
import ContactManager from "../managers/ContactManager";
import PermissionService from "../services/common/permission";
import { store } from "../redux/store";
import TenantManager from "../managers/TenantManager";
import { getCurrencySymbolFromCode } from "../constants/Currencies";
import {
  CONTACT_STATUS_ACTIVE,
  LOOKUP_FIELD_ENABLE_COL_TYPE,
  TICKET_COLUMNS
} from "../constants/Constant";
import Table from "../services/table";
import { DATE_FORMATS, DateUtil } from "../utility/Date";
import BooksService from "../services/books";
import SupportTicketService from "../services/supportTicket";

class TableDataParser {
  static parseTableData(data) {
    let tableData = {};
    tableData["id"] = data._id;
    tableData["name"] = data.name;
    tableData["columns"] = this.parseColumnData(data.columnsMetaData);
    return tableData;
  }
  static parseColumnData(data) {
    let columnsData = [];
    data.forEach((column) => {
      let data = { ...column };
      data["key"] = column.id;
      data["type"] = column.type.toLowerCase();
      data["options"] = column.options ? column.options : [];
      data["required"] = column.required ? column.required : false;
      data["width"] = column.width ? column.width : 200;

      /* Hiding formula column from filters, detail pages, automation, campaigns, forms */
      if (column.type === INPUT_TYPE.FORMULA) {
        data["allowFilter"] = false;
        data["uiVisible"] = false;
      }
      columnsData.push(data);
    });
    return columnsData;
  }
  static parseRowData(data, table = null) {
    return data.map((row) => {
      this.checkAndReplaceDirectLink(row, table); // TODO - Need to remove this @Piyush this is a workaround
      TableDataParser.addDefaultCampaignTypeToRowData(row, table);
      return { ...row.cells, id: row._id };
    });
  }
  static getColumnToAdd(column, dataSourceProps = null) {
    return {
      name: column.name,
      type: dataSourceProps?.type || column.type,
      options: column.options || [],
      required: column.required || false,
      width: column.width || 200,
      editable: !Utility.isEmptyObject(dataSourceProps?.lookup)
        ? true
        : dataSourceProps
          ? false
          : column.editable,
      hidden: column.hidden || false,
      systemField: column.systemField || false,
      uiVisible: column.uiVisible || true,
      datasource: dataSourceProps?.datasource,
      lookup: dataSourceProps?.lookup,
      formula: column.formula || null
    };
  }
  static getRowToAdd(rowData) {
    let cells = {};
    for (let key in rowData) {
      cells[key] = rowData[key].value;
    }
    return { cells };
  }

  static getSanitizedJsonData(jsonData) {
    try {
      const jsonString = JSON.stringify(jsonData);
      const sanitizedData = JSON.parse(sanitizeJSON(jsonString));
      return sanitizedData;
    } catch (err) {
      return jsonData;
    }
  }

  /** ************** FILTER PAYLOAD HELPERS ***************/

  /**
   * @description
   * This method modifies the filter condition based on DATE or COLUMN CODES
   * (for handling equalTo & greater than operations)
   */
  static updateFilterConditionForPayload(filterCondition, columns) {
    const updatedFilterConditions = [];
    const columnData = columns?.find(
      (column) => column.id === filterCondition?.colId
    );

    if (!Utility.isEmptyObject(filterCondition)) {
      filterCondition = { ...filterCondition };
      delete filterCondition.isExternal;
    }

    if (
      columnData?.type !== INPUT_TYPE.DATE ||
      !Utility.isValidDate(filterCondition.value)
    ) {
      if (ActivityManager.isActivityOverdueFilter(filterCondition)) {
        let overDueConditions = ActivityManager.getActivityReportQuery();
        updatedFilterConditions.push(...overDueConditions);
      } else if (!Utility.isEmptyObject(filterCondition)) {
        if (columnData?.type === "dropdown") {
          /**
           * @description- the dropdown type column has object as an value we need to pick the id from the object and pass as an array
           * */
          if (filterCondition?.value?.id) {
            filterCondition = {
              ...filterCondition,
              value: [filterCondition?.value?.id]
            };
          }
        }
        updatedFilterConditions.push(filterCondition);
      }

      return {
        conditions: updatedFilterConditions,
        logicalOperator: FILTER_LOGICAL_OPERATORS.AND
      };
    }

    const selectedDate = new Date(filterCondition.value);
    const nextDate = new Date(
      new Date(selectedDate).setDate(selectedDate.getDate() + 1)
    );
    if (filterCondition.opr === FILTER_OPERATORS.EQUAL) {
      updatedFilterConditions.push({
        colId: filterCondition.colId,
        value: selectedDate,
        opr: FILTER_OPERATORS.GREATER_THAN_OR_EQUAL
      });

      updatedFilterConditions.push({
        colId: filterCondition.colId,
        value: nextDate,
        opr: FILTER_OPERATORS.LESS_THAN
      });
    } else if (filterCondition.opr === FILTER_OPERATORS.GREATER_THAN) {
      updatedFilterConditions.push({
        colId: filterCondition.colId,
        value: nextDate,
        opr: FILTER_OPERATORS.GREATER_THAN_OR_EQUAL
      });
    } else {
      updatedFilterConditions.push(filterCondition);
    }

    return {
      conditions: updatedFilterConditions,
      logicalOperator: FILTER_LOGICAL_OPERATORS.AND
    };
  }

  /**
   * @description
   * This method creates groupings for filter payload, to apply logical operations on conditions.
   * External conditions (module level) are always processed with "AND" operators,
   * while GridLevel/additional Filters are processed with user selected operator.
   */
  static getModifiedFilterPayload(
    payload,
    columns,
    splitOnExternalConditions = true
  ) {
    const { logicalOperator, conditions } = payload;
    if (!conditions?.length)
      return { conditions: [], logicalOperator: logicalOperator };

    let filter1, filter2;

    if (splitOnExternalConditions) {
      filter1 = TableDataParser.getModifiedFilterPayload(
        {
          conditions: conditions.filter((condition) => condition.isExternal),
          logicalOperator: FILTER_LOGICAL_OPERATORS.AND
        },
        columns,
        false
      );
      filter2 = TableDataParser.getModifiedFilterPayload(
        {
          conditions: conditions.filter((condition) => !condition.isExternal),
          logicalOperator
        },
        columns,
        false
      );
    } else {
      filter1 = TableDataParser.updateFilterConditionForPayload(
        conditions[0],
        columns
      );
      filter2 =
        conditions?.length > 2
          ? TableDataParser.getModifiedFilterPayload(
              {
                conditions: conditions.slice(1),
                logicalOperator
              },
              columns,
              false
            )
          : TableDataParser.updateFilterConditionForPayload(
              conditions[1],
              columns
            );
    }

    return {
      filter1,
      filter2,
      logicalOperator: splitOnExternalConditions
        ? FILTER_LOGICAL_OPERATORS.AND
        : logicalOperator
    };
  }

  /** ************** SEGMENT COLUMN RENDERERS ***************/
  static checkAndReplaceDirectLink(row, table) {
    if (table !== null) {
      const column_id = TableManger.getColumnId(
        table,
        COLUMN_CODE.FORMS.DIRECT_LINK
      );
      if (column_id) {
        row.cells[column_id] = AppManager.generateDirectLink(
          row.cells[column_id]
        );
      }
    }
  }
  static checkAndReplaceSegment() {
    const contactSegmentCol = TableManger.getColumn(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.SEGMENT
    );
    let segmentData = {
      type: contactSegmentCol.type,
      options: contactSegmentCol.options,
      name: contactSegmentCol.name
    };
    TableManger.updateColumn(COLUMN_CODE.FORMS.SEGMENT_ID, TABLES.FORM, {
      ...TableManger.getColumn(TABLES.FORM, COLUMN_CODE.FORMS.SEGMENT_ID),
      ...segmentData
    });
    TableManger.updateColumn(COLUMN_CODE.CAMPAIGN.SEGMENT_ID, TABLES.CAMPAIGN, {
      ...TableManger.getColumn(
        TABLES.CAMPAIGN,
        COLUMN_CODE.CAMPAIGN.SEGMENT_ID
      ),
      ...segmentData
    });
  }

  /* ***************** CAMPAIGN GRID UTILS ***************** */
  static addDefaultCampaignTypeToRowData(row, table) {
    if (table !== TABLES.CAMPAIGN) return;

    const column_id = TableManger.getColumnId(
      table,
      COLUMN_CODE.CAMPAIGN.CAMPAIGN_TYPE
    );
    if (
      column_id &&
      row?.cells &&
      Utility.isEmptyObject(row.cells[column_id])
    ) {
      row.cells[column_id] = [CAMPAIGN_TYPE.EMAIL];
    }
  }

  /***************** ACTIVITY GRID RENDERERS ****************/
  static checkAndReplaceActivityData(data, users) {
    const CONTACT_IDS = TableManger.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.CONTACT_IDS
    );
    const ORGANIZATION_IDS = TableManger.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS
    );
    const LINKED_TO = TableManger.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.LINKED_TO
    );

    const ACCOUNT_TO = TableManger.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.ACCOUNT_ID
    );

    let contactOptions = [],
      organizationOptions = [],
      dealOptions = [],
      ownerOptions = [],
      accountOptions = [];

    data?.forEach((row) => {
      if (row.cells[CONTACT_IDS + "_detail"]?.length >= 0) {
        contactOptions = [
          ...contactOptions,
          ...ActivityManager.contactData(row.cells[CONTACT_IDS + "_detail"])
        ];
      }
      if (row.cells[ORGANIZATION_IDS + "_detail"]?.length >= 0) {
        organizationOptions = [
          ...organizationOptions,
          ...ActivityManager.contactData(
            row.cells[ORGANIZATION_IDS + "_detail"]
          )
        ];
      }
      if (row.cells[LINKED_TO + "_detail"]?.length >= 0) {
        dealOptions = [
          ...dealOptions,
          ...ActivityManager.dealData(row.cells[LINKED_TO + "_detail"])
        ];
      }
      if (row.cells[ACCOUNT_TO + "_detail"]?.length >= 0) {
        accountOptions = [
          ...accountOptions,
          ...ActivityManager.accountData(row.cells[ACCOUNT_TO + "_detail"])
        ];
      }
    });

    if (contactOptions.length >= 0) {
      contactOptions = [
        ...new Map(
          contactOptions.map((contact) => [contact.id, contact])
        ).values()
      ];
      let contactData = {
        options: contactOptions,
        type: INPUT_TYPE.SELECT
      };
      TableManger.updateColumn(
        COLUMN_CODE.ACTIVITY.CONTACT_IDS,
        TABLES.ACTIVITY,
        {
          ...TableManger.getColumn(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.CONTACT_IDS
          ),
          ...contactData
        }
      );
    }

    if (organizationOptions.length >= 0) {
      organizationOptions = [
        ...new Map(
          organizationOptions.map((organization) => [
            organization.id,
            organization
          ])
        ).values()
      ];
      let organizationData = {
        options: organizationOptions,
        type: INPUT_TYPE.SELECT
      };
      TableManger.updateColumn(
        COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS,
        TABLES.ACTIVITY,
        {
          ...TableManger.getColumn(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS
          ),
          ...organizationData
        }
      );
    }

    if (dealOptions.length >= 0) {
      dealOptions = [
        ...new Map(dealOptions.map((deal) => [deal.id, deal])).values()
      ];
      let dealData = {
        options: dealOptions,
        type: INPUT_TYPE.SELECT
      };
      TableManger.updateColumn(
        COLUMN_CODE.ACTIVITY.LINKED_TO,
        TABLES.ACTIVITY,
        {
          ...TableManger.getColumn(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.LINKED_TO
          ),
          ...dealData
        }
      );
    }

    if (accountOptions.length >= 0) {
      accountOptions = [
        ...new Map(
          accountOptions.map((account) => [account.id, account])
        ).values()
      ];
      let accountData = {
        options: accountOptions,
        type: INPUT_TYPE.SELECT
      };
      TableManger.updateColumn(
        COLUMN_CODE.ACTIVITY.ACCOUNT_ID,
        TABLES.ACTIVITY,
        {
          ...TableManger.getColumn(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.ACCOUNT_ID
          ),
          ...accountData,
          allowAddOption: false
        }
      );
    }

    if (ownerOptions.length === 0) {
      const ownerOptions = [];
      users.forEach((user, index) => {
        if (user.status === INVITE_USER_STATUS.JOINED) {
          ownerOptions.push({
            id: user.iamUserId,
            name: getFullName(user),
            color: `data-grid-badge-color-${10 - (index % 10)}`
          });
        }
      });
      let ownerData = {
        options: ownerOptions,
        type: INPUT_TYPE.SELECT
      };
      TableManger.updateColumn(COLUMN_CODE.ACTIVITY.OWNER_ID, TABLES.ACTIVITY, {
        ...TableManger.getColumn(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.OWNER_ID
        ),
        ...ownerData,
        allowFilter: false,
        allowAddOption: false
      });
    }
  }

  /***************** COMMON UTILS FOR LINKED COLUMNS ****************/
  static getLinkedRecordNameRenderer = (linkedColumnTableName, columnId) => {
    let counter = 0;
    const recordColors = {};

    return ({ rowData }) => {
      const nameColumnId = TableManger.getColumnId(
        linkedColumnTableName,
        `name`
      );
      const linkedRecords =
        rowData[`${columnId}_detail`]?.filter(
          (record) => !Utility.isEmptyObject(record?.cells)
        ) || [];
      const linkedRecord = linkedRecords?.[0];
      let colorId = 0;

      if (!linkedRecord) return null;

      if (recordColors[linkedRecord._id]) {
        colorId = recordColors[linkedRecord._id];
      } else {
        colorId = 10 - (counter % 10);
        recordColors[linkedRecord._id] = colorId;
        counter++;
      }

      return (
        <DKLabel
          className={`row border-radius-s data-grid-badge-color-${colorId} width-auto`}
          style={{
            padding: "2px 6px"
          }}
          text={linkedRecord.cells?.[nameColumnId]}
        />
      );
    };
  };

  static getLinkedRecordCreateButtonConfig = (linkedRecordTableName) => {
    const isActionPermitted = PermissionService.getInstance().isUserPermitted(
      linkedRecordTableName,
      [USER_ACTION_TYPES.REC_CREATE]
    );

    if (!isActionPermitted) return null;

    switch (linkedRecordTableName) {
      case TABLES.ACCOUNT:
        return {
          title: "+ Add Account",
          className: "bg-button text-white",
          onClick: () => showAddAccountPopup()
        };
      case TABLES.CONTACT:
        return {
          title: "+ Add Contact",
          className: "bg-button text-white",
          onClick: () =>
            import("../components/menu/AddNewRecordPopup").then(
              ({ showFormPopup }) =>
                showFormPopup({
                  tableName: TABLES.CONTACT,
                  defaultValues: ContactManager.getDefaultValues()
                })
            )
        };
      default:
    }

    return null;
  };

  static getLinkedRecordColumnDropdownConfig = (
    tableName,
    linkedRecordTableName
  ) => {
    let accountCode = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.ACCOUNT
    );
    return {
      title: `Select ${linkedRecordTableName}`,
      allowSearch: true,
      allowRemoveSelection: true,
      searchableKey: "name",
      style: { minWidth: 230 },
      className: "z-index-1 shadow-m",
      searchApiConfig: {
        method: "POST",
        getUrl: (val) => {
          return (
            ApiConstants.URL.BASE +
            ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
              TableManger.getTableId(linkedRecordTableName)
            ) +
            "?pageNo=1&pageSize=20"
          );
        },
        getPayload: (search) => {
          let filterCondition = [
            {
              colId: TableManger.getColumnId(linkedRecordTableName, `name`),
              opr: "c",
              value: search
            }
          ];
          try {
            const isEnableAccountLinked =
              store.getState().tenant?.crmSettings?.isDealRequiresAccount ??
              false;
            if (tableName === TABLES.DEAL && isEnableAccountLinked) {
              filterCondition.push({
                colId: accountCode,
                opr: "neq",
                value: []
              });
            }
          } catch (e) {
            console.error(e);
          }
          return {
            logicalOperator: "and",
            conditions: filterCondition
          };
        },
        dataParser: (response) => {
          if (linkedRecordTableName === TABLES.CONTACT) {
            //special case for account key is missing in response
            let responseData = response.data;
            const statusColumnID = TableManger.getColumnId(
              TABLES.CONTACT,
              COLUMN_CODE.CONTACT.STATUS
            );
            responseData = responseData.filter(
              (list) =>
                list.cells?.[statusColumnID]?.[0] === CONTACT_STATUS_ACTIVE
            );
            const isEnableAccountLinked =
              store.getState().tenant?.crmSettings?.isDealRequiresAccount ??
              false;
            if (isEnableAccountLinked && tableName === TABLES.DEAL) {
              responseData = responseData.filter(
                (list) =>
                  [accountCode] in list.cells &&
                  list.cells[statusColumnID][0] === CONTACT_STATUS_ACTIVE
              );
            }
            let personList = DealManager.contactData(responseData);
            return personList;
          } else if (linkedRecordTableName === TABLES.ACCOUNT) {
            let accountList = AccountService.parseAccountPayload(
              response?.data || []
            );
            return accountList;
          }
        }
      },
      data: [],
      renderer: (index, obj) => {
        return (
          <div className="column parent-width">
            <DKLabel text={`${obj.name}`} />
            {(obj.organization || obj.accountName) &&
            tableName === TABLES.DEAL ? (
              <div className="row justify-content-between align-items-start">
                <DKLabel
                  text={`${obj?.organization ?? ""}`}
                  className="text-gray fs-s text-trailing"
                  style={{ maxWidth: "49%" }}
                />
                <DKLabel
                  text={`${obj?.accountName ?? ""}`}
                  className="text-gray fs-s text-trailing"
                  style={{ maxWidth: "49%" }}
                />
              </div>
            ) : null}
            {obj.email && tableName === TABLES.ACCOUNT ? (
              <DKLabel text={`${obj.email}`} className="text-gray fs-s" />
            ) : null}
          </div>
        );
      },
      onSelect: (index, obj, rowIndex) => {},
      button: TableDataParser.getLinkedRecordCreateButtonConfig(
        linkedRecordTableName
      )
    };
  };

  static getPriceBookColumnDropdownConfig = (column) => {
    const filterDates = {
      ...BooksService.getFinancialStartEndDate(),
      financialStartDate:
        BooksService.getFinancialBeginningDate()?.financialBeginningDate
    };
    let startDateString = DateUtil.getDateStrFromDate(
      new Date(filterDates?.financialStartDate),
      DATE_FORMATS["DD-MM-YYYY"]
    );
    let endDateString = DateUtil.getDateStrFromDate(
      new Date(filterDates?.financialEndDate),
      DATE_FORMATS["DD-MM-YYYY"]
    );
    let query = `isAdvance=true`;
    return {
      title: `Select price book`,
      allowSearch: true,
      allowRemoveSelection: true,
      searchableKey: "name",
      style: { minWidth: 230 },
      className: "z-index-1 shadow-m",
      multiSelect: false,
      searchApiConfig: {
        method: "GET",
        getUrl: (search) =>
          ApiConstants.URL.BASE +
          `v1/products/price-list/search?search=${search}&query=${query}`,
        getPayload: (search) => {},
        dataParser: (response) => {
          let dataToParse = [];
          if (
            response?.code === PRICE_BOOK_PERMISSION_ERROR_CODE.UNAUTHORIZED
          ) {
            dataToParse = [];
          } else {
            dataToParse = response?.content?.filter(
              (priceBook) => priceBook.active
            );
          }
          return dataToParse;
        }
      },
      data: [],
      renderer: (index, obj) => {
        return <DKLabel text={`${obj.name}`} />;
      },
      onSelect: (index, obj, rowIndex) => {}
    };
  };

  /** ************** DEAL GRID RENDERERS ********************/
  static putDataInOwnersColumn(userList) {
    const availableUsers =
      userList?.filter((user) => user.status === INVITE_USER_STATUS.JOINED) ||
      [];

    const ownerOptions = availableUsers.map((user, index) => ({
      id: user.iamUserId,
      name: getFullName(user),
      color: `data-grid-badge-color-${(index % 10) + 1}`,
      crm3RoleId: user.crm3RoleId
    }));

    const contactOwnerIdColumn = TableManger.getColumn(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.OWNER_ID
    );
    TableManger.updateColumn(COLUMN_CODE.CONTACT.OWNER_ID, TABLES.CONTACT, {
      ...contactOwnerIdColumn,
      name: "Owner",
      type: INPUT_TYPE.SELECT,
      options: ownerOptions,
      allowAddOption: false
      /* allowFilter: false */
    });
    TableManger.updateColumn(COLUMN_CODE.CONTACT.SUB_OWNER_ID, TABLES.CONTACT, {
      ...TableManger.getColumn(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.SUB_OWNER_ID
      ),
      name: "Sub Owner",
      type: INPUT_TYPE.MULTI_SELECT,
      options: ownerOptions,
      allowAddOption: false
    });

    TableManger.updateColumn(COLUMN_CODE.ACCOUNT.OWNER_ID, TABLES.ACCOUNT, {
      ...TableManger.getColumn(TABLES.ACCOUNT, COLUMN_CODE.ACCOUNT.OWNER_ID),
      name: "Owner",
      type: INPUT_TYPE.SELECT,
      options: ownerOptions,
      allowAddOption: false,
      allowFilter: false
    });
    TableManger.updateColumn(COLUMN_CODE.ACCOUNT.SUB_OWNER_ID, TABLES.ACCOUNT, {
      ...TableManger.getColumn(
        TABLES.ACCOUNT,
        COLUMN_CODE.ACCOUNT.SUB_OWNER_ID
      ),
      name: "Sub Owner",
      type: INPUT_TYPE.MULTI_SELECT,
      options: ownerOptions,
      allowAddOption: false
    });

    const dealOwnerIdColumn = TableManger.getColumn(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.OWNER_ID
    );
    TableManger.updateColumn(COLUMN_CODE.DEAL.OWNER_ID, TABLES.DEAL, {
      ...dealOwnerIdColumn,
      name: "Owner",
      type: INPUT_TYPE.SELECT,
      options: ownerOptions,
      allowAddOption: false,
      allowFilter: false
    });
    TableManger.updateColumn(COLUMN_CODE.DEAL.SUB_OWNER_ID, TABLES.DEAL, {
      ...TableManger.getColumn(TABLES.DEAL, COLUMN_CODE.DEAL.SUB_OWNER_ID),
      name: "Sub Owner",
      type: INPUT_TYPE.MULTI_SELECT,
      options: ownerOptions,
      allowAddOption: false
    });

    const activityOwnerIdColumn = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.OWNER_ID
    );
    TableManger.updateColumn(COLUMN_CODE.ACTIVITY.OWNER_ID, TABLES.ACTIVITY, {
      ...activityOwnerIdColumn,
      type: INPUT_TYPE.SELECT,
      options: ownerOptions,
      allowFilter: false,
      allowAddOption: false
    });

    const ticketAssigneeColumn = TableManger.getColumn(
      TABLES.TICKET,
      COLUMN_CODE.TICKET.ASSIGNEE
    );
    TableManger.updateColumn(COLUMN_CODE.TICKET.ASSIGNEE, TABLES.TICKET, {
      ...ticketAssigneeColumn,
      options: ownerOptions,
      allowAddOption: false
    });

    const ticketCreatedByColumn = TableManger.getColumn(
      TABLES.TICKET,
      COLUMN_CODE.TICKET.CREATED_BY
    );
    TableManger.updateColumn(COLUMN_CODE.TICKET.CREATED_BY, TABLES.TICKET, {
      ...ticketCreatedByColumn,
      options: ownerOptions,
      allowAddOption: false
    });

    this.updateNoteColumn(TABLES.CONTACT, COLUMN_CODE.CONTACT.NOTE, {
      hidden: false
    });
    this.updateNoteColumn(TABLES.DEAL, COLUMN_CODE.DEAL.NOTE, {
      hidden: false
    });
  }

  static updateNoteColumn = (tableName, column, values) => {
    const noteColumn = TableManger.getColumn(tableName, column);
    TableManger.updateColumn(column, tableName, {
      ...noteColumn,
      ...values
    });
  };

  static amountFormatter = (obj) => {
    let currencyExist = obj?.rowData
      ? Object.keys(obj?.rowData)?.filter((row) =>
          row?.includes?.(COLUMN_CODE.DEAL.CURRENCY_CODE)
        )
      : [];
    let currencyCode = TenantManager.getCRMCurrencyCode();
    if (currencyExist.length > 0) {
      currencyCode = obj.rowData[currencyExist[0]];
    }
    return toCurrencyFormat(
      obj.value || 0,
      getCurrencySymbolFromCode(currencyCode)
    );
  };

  static defaultAmountFormatter = (obj) => {
    let currencyCode = TenantManager.getCRMCurrencyCode();
    return toCurrencyFormat(
      obj.value || 0,
      getCurrencySymbolFromCode(currencyCode)
    );
  };

  static updateDealColumns() {
    const contactIdColumn = TableManger.getColumn(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.CONTACT_ID
    );
    TableManger.updateColumn(COLUMN_CODE.DEAL.CONTACT_ID, TABLES.DEAL, {
      ...contactIdColumn,
      // name: "Contact", commented for ticket No ZEN-11170
      hidden: false,
      allowFilter: false,
      type: INPUT_TYPE.DROPDOWN,
      dropdownConfig: this.getLinkedRecordColumnDropdownConfig(
        TABLES.DEAL,
        TABLES.CONTACT
      ),
      renderer: this.getLinkedRecordNameRenderer(
        TABLES.CONTACT,
        contactIdColumn.id
      )
    });

    const accountIdColumn = TableManger.getColumn(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.ACCOUNT_ID
    );
    TableManger.updateColumn(COLUMN_CODE.DEAL.ACCOUNT_ID, TABLES.DEAL, {
      ...accountIdColumn,
      hidden: false,
      allowFilter: false,
      type: INPUT_TYPE.DROPDOWN,
      dropdownConfig: this.getLinkedRecordColumnDropdownConfig(
        TABLES.DEAL,
        TABLES.ACCOUNT
      ),
      renderer: this.getLinkedRecordNameRenderer(
        TABLES.ACCOUNT,
        accountIdColumn.id
      )
    });

    const dealAmountColumn = TableManger.getColumn(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.AMOUNT
    );
    TableManger.updateColumn(COLUMN_CODE.DEAL.AMOUNT, TABLES.DEAL, {
      ...dealAmountColumn,
      formatter: this.amountFormatter
    });

    const stageIdColumn = TableManger.getColumn(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.STAGE_ID
    );
    TableManger.updateColumn(COLUMN_CODE.DEAL.STAGE_ID, TABLES.DEAL, {
      ...stageIdColumn,
      hidden: false,
      allowFilter: true,
      allowAddOption: false
    });
  }

  /** ************** CONTACT GRID RENDERERS ********************/
  static updatePriceBookColumnInContacts = (tableName) => {
    const priceListColumn = Utility.deepCloneObject(
      TableManger.getColumn(TABLES.CONTACT, COLUMN_CODE.CONTACT.PRICE_LIST)
    );
    TableManger.updateColumn(COLUMN_CODE.CONTACT.PRICE_LIST, TABLES.CONTACT, {
      ...priceListColumn,
      allowFilter: true,
      allowColumnSort: false,
      type: INPUT_TYPE.DROPDOWN,
      dropdownConfig: this.getPriceBookColumnDropdownConfig(priceListColumn),
      editable: true
    });
  };
  static updateAccountColumnInContacts = () => {
    const accountColumn = Utility.deepCloneObject(
      TableManger.getColumn(TABLES.CONTACT, COLUMN_CODE.CONTACT.ACCOUNT)
    );

    TableManger.updateColumn(COLUMN_CODE.CONTACT.ACCOUNT, TABLES.CONTACT, {
      ...accountColumn,
      allowFilter: true,
      type: INPUT_TYPE.DROPDOWN,
      dropdownConfig: this.getLinkedRecordColumnDropdownConfig(
        TABLES.CONTACT,
        TABLES.ACCOUNT
      ),
      renderer: this.getLinkedRecordNameRenderer(
        TABLES.ACCOUNT,
        accountColumn.id
      ),
      editable: PermissionService.getInstance().isUserPermitted(
        TABLES.ACCOUNT,
        [USER_ACTION_TYPES.ASSIGN_CONTACT]
      )
    });
  };

  static updateAccountColumnInDeal = () => {
    const accountColumn = Utility.deepCloneObject(
      TableManger.getColumn(TABLES.DEAL, COLUMN_CODE.DEAL.ACCOUNT_ID)
    );
    TableManger.updateColumn(COLUMN_CODE.DEAL.ACCOUNT_ID, TABLES.DEAL, {
      ...accountColumn,
      allowFilter: true,
      type: INPUT_TYPE.DROPDOWN,
      dropdownConfig: this.getLinkedRecordColumnDropdownConfig(
        TABLES.DEAL,
        TABLES.ACCOUNT
      ),
      renderer: this.getLinkedRecordNameRenderer(
        TABLES.ACCOUNT,
        accountColumn?.id
      ),
      editable: true
    });
  };
  static getOptionName = (option, isDate, isAmount, isNumber) => {
    let optionName = option;
    if (!Utility.isEmptyObject(optionName)) {
      if (isDate) {
        optionName = DateUtil.getDateStrFromDate(
          new Date(option),
          DateUtil.getOrgDateFormat()
        );
      }
      if (isAmount || isNumber) {
        optionName = parseFloat(option)?.toFixed(2);
      }
    }
    return optionName;
  };

  static getLookupColumnDropdownConfig = (
    linkedRecordTableName,
    filterConditions
  ) => {
    const sourceTableColumns = TableManger.getTableColumns(
      TableManger.getTableNameFromId(filterConditions?.tableId)
    );
    const contactNumberColumnId = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.DOCUMENT_SEQUENCE_CODE
    );
    let isDateType = false;
    let isAmountType = false;
    let isNumberType = false;
    sourceTableColumns.forEach((column) => {
      if ((column.key || column.id) === filterConditions?.sourceColumn) {
        if (column.type === INPUT_TYPE.DATE) {
          isDateType = true;
        }
        if (column.type === INPUT_TYPE.NUMBER) {
          isAmountType = true;
        } else if (
          column.type === INPUT_TYPE.NUMBER &&
          (column.id || column.columnCode) === contactNumberColumnId
        ) {
          isNumberType = true;
        }
      }
    });
    return {
      title: `Select ${linkedRecordTableName}`,
      allowSearch: isDateType || isNumberType || isAmountType ? false : true,
      allowRemoveSelection: false,
      searchableKey: "name",
      style: { minWidth: 230 },
      className: "z-index-1 shadow-m",
      searchApiConfig: {
        method: "POST",
        getUrl: (val) => {
          return (
            ApiConstants.URL.BASE +
            ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
              filterConditions.tableId
            ) +
            "?pageNo=1&pageSize=20&q=" +
            val
          );
        },
        getPayload: (search) => {
          return filterConditions?.filter;
        },
        dataParser: (response) => {
          return (
            response?.data?.map((record) => ({
              id: record._id,
              name: TableDataParser.getOptionName(
                record.cells[filterConditions?.sourceColumn],
                isDateType,
                isAmountType,
                isNumberType
              )
            })) || []
          )?.filter(
            (option) => option?.name && !Utility.isEmptyObject(option?.name)
          );
        }
      },
      data: [],
      renderer: (index, obj) => {
        return (
          <div className="column parent-width">
            <DKLabel text={`${obj.name}`} />
          </div>
        );
      },
      onSelect: (index, obj, rowIndex) => {}
    };
  };

  static getLookupNameRenderer = (columnId) => {
    return ({ value, rowData }) => {
      return value?.length > 0 ? (
        <div className="row">
          {rowData[columnId + "_detail"]?.map((row) => (
            <DKLabel
              className={`row border-radius-s data-grid-badge-color-1 fs-r width-auto`}
              style={{
                padding: "2px 6px"
              }}
              text={row?.processedValue || ""}
            />
          ))}
        </div>
      ) : (
        ""
      );
    };
  };

  /***************************** Account Price Book Column ***********************************/
  static updatePriceBookColumnInAccount = (tableName) => {
    const priceListColumn = Utility.deepCloneObject(
      TableManger.getColumn(TABLES.ACCOUNT, COLUMN_CODE.ACCOUNT.PRICE_LIST)
    );
    TableManger.updateColumn(COLUMN_CODE.ACCOUNT.PRICE_LIST, TABLES.ACCOUNT, {
      ...priceListColumn,
      allowFilter: true,
      allowColumnSort: false,
      type: INPUT_TYPE.DROPDOWN,
      dropdownConfig: this.getPriceBookColumnDropdownConfig(priceListColumn),
      editable: true
    });
  };

  /***************************** Update Lookup field update***********************************/
  static updateLookupContactFields = () => {
    const contactColumns = Utility.deepCloneObject(
      TableManger.getTableColumns(TABLES.CONTACT)
    );

    const filteredLookupColumns = contactColumns.filter(
      (column) =>
        LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) &&
        !Utility.isEmptyObject(column.lookup)
    );

    filteredLookupColumns?.forEach((column) => {
      let filterConditions = {
        filter: column.lookup.filter,
        tableId: column.lookup.sourceTableId,
        sourceColumn: column.lookup.sourceColumn
      };

      TableManger.updateColumnById(column.id, TABLES.CONTACT, {
        ...column,
        allowSearch: true,
        searchableKey: "name",
        type: INPUT_TYPE.DROPDOWN,
        dropdownConfig: this.getLookupColumnDropdownConfig(
          column.name,
          filterConditions
        ),
        renderer: this.getLookupNameRenderer(column.id),
        editable: !PermissionService.getInstance().isUserPermitted(
          TABLES.CONTACT,
          [USER_ACTION_TYPES.REC_CREATE, USER_ACTION_TYPES.UPDATE]
        )
      });
    });
  };

  static updateLookupAccountFields = () => {
    const accountColumns = Utility.deepCloneObject(
      TableManger.getTableColumns(TABLES.ACCOUNT)
    );

    const filteredLookupColumns = accountColumns.filter(
      (column) =>
        LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) &&
        !Utility.isEmptyObject(column.lookup)
    );

    filteredLookupColumns?.forEach((column) => {
      let filterConditions = {
        filter: column.lookup.filter,
        tableId: column.lookup.sourceTableId,
        sourceColumn: column.lookup.sourceColumn
      };
      TableManger.updateColumnById(column.id, TABLES.ACCOUNT, {
        ...column,
        allowSearch: true,
        searchableKey: "name",
        type: INPUT_TYPE.DROPDOWN,
        dropdownConfig: this.getLookupColumnDropdownConfig(
          column.name,
          filterConditions
        ),
        renderer: this.getLookupNameRenderer(column.id),
        editable: !PermissionService.getInstance().isUserPermitted(
          TABLES.ACCOUNT,
          [USER_ACTION_TYPES.REC_CREATE, USER_ACTION_TYPES.UPDATE]
        )
      });
    });
  };

  static updateLookupDealFields = () => {
    const dealColumns = Utility.deepCloneObject(
      TableManger.getTableColumns(TABLES.DEAL)
    );

    const filteredLookupColumns = dealColumns.filter(
      (column) =>
        LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) &&
        !Utility.isEmptyObject(column.lookup)
    );

    filteredLookupColumns?.forEach((column) => {
      let filterConditions = {
        filter: column.lookup.filter,
        tableId: column.lookup.sourceTableId,
        sourceColumn: column.lookup.sourceColumn
      };
      TableManger.updateColumnById(column.id, TABLES.DEAL, {
        ...column,
        allowSearch: true,
        searchableKey: "name",
        type: INPUT_TYPE.DROPDOWN,
        dropdownConfig: this.getLookupColumnDropdownConfig(
          column.name,
          filterConditions
        ),
        renderer: this.getLookupNameRenderer(column.id),
        editable: !PermissionService.getInstance().isUserPermitted(
          TABLES.DEAL,
          [USER_ACTION_TYPES.REC_CREATE, USER_ACTION_TYPES.UPDATE]
        )
      });
    });
  };

  static updateLookupActivityFields = () => {
    const activityColumns = Utility.deepCloneObject(
      TableManger.getTableColumns(TABLES.ACTIVITY)
    );

    const filteredLookupColumns = activityColumns.filter(
      (column) =>
        LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) &&
        !Utility.isEmptyObject(column.lookup)
    );

    filteredLookupColumns?.forEach((column) => {
      let filterConditions = {
        filter: column.lookup.filter,
        tableId: column.lookup.sourceTableId,
        sourceColumn: column.lookup.sourceColumn
      };
      TableManger.updateColumnById(column.id, TABLES.ACTIVITY, {
        ...column,
        allowSearch: true,
        searchableKey: "name",
        type: INPUT_TYPE.DROPDOWN,
        dropdownConfig: this.getLookupColumnDropdownConfig(
          column.name,
          filterConditions
        ),
        renderer: this.getLookupNameRenderer(column.id),
        editable: !PermissionService.getInstance().isUserPermitted(
          TABLES.ACTIVITY,
          [USER_ACTION_TYPES.REC_CREATE, USER_ACTION_TYPES.UPDATE]
        )
      });
    });
  };

  static fetchAndUpdateCustomTicketFields = () => {
    let queryParams = `?query=appName=${"CRM"},module=${"TICKET"}&limit=1000`;
    SupportTicketService.getCustomFields(queryParams).then((response) =>
      this.updateTicketCustomFields(response?.content ?? [])
    );
  };

  static updateTicketCustomFields = (ticketCFColumns) => {
    const ticketColumns = TICKET_COLUMNS;
    let ticketCustomFields = ticketCFColumns;

    ticketCustomFields = ticketCustomFields?.filter(
      (field) => field.fieldType !== "DATE"
    );

    ticketCustomFields = ticketCustomFields?.map((field, index) => ({
      ...field,
      name: field?.label,
      options: field?.attributes?.map((attribute) => ({
        id: attribute?.id,
        name: attribute?.value
      })),
      type:
        field?.fieldType === "DROPDOWN"
          ? INPUT_TYPE.SELECT
          : field?.fieldType?.toLowerCase(),
      columnCode: field?.code,
      id: "custom_field." + field.code,
      key: field?.id,
      systemField: false,
      uiVisible: true,
      editable: true
    }));

    let data = {
      _id: TableManger.getTableId(TABLES.TICKET),
      name: TABLES.TICKET,
      columnsMetaData: [...ticketColumns, ...ticketCustomFields].map(
        (column, index) => ({ ...column, index })
      )
    };

    TableManger.setTableColumns(Utility.deepCloneObject(data), TABLES.TICKET);
  };
}

export default TableDataParser;
